import { FC } from "react"
import React from "reactn"
import { graphql } from "gatsby"
import marked from "marked"
import Img, { ImgProps } from "../components/Img"
import Link from "../components/Link"
import PageHeader from "../components/PageHeader"
import Meta, { MetaProps } from "../components/Meta"

interface UtviklingPageTemplateProps {
  intro: {
    img: ImgProps,
    title: string,
    text: string,
    link: {
      href: string,
      text: string
    }
  },

  company: {
    title: string,
    text: string,
    img: ImgProps
  },

  partnership: {
    img: ImgProps,
    title: string,
    text: string
  },

  strategy: {
    img: ImgProps,
    title: string,
    text: string
  }
}

const UtviklingPageTemplate: FC<UtviklingPageTemplateProps> = ({
  intro,
  company,
  partnership,
  strategy
}) => {
  return (
    <main id="utvikling-page">
      <section
        id="intro"
        className="bg-cover bg-center h-screen"
        style={{ backgroundImage: `url("${intro.img.src}")` }}
      >
        <div
          className="h-full w-full flex flex-col items-center justify-center px-6"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.66)"
          }}
        >
          <h1 className="title text-white">
            {intro.title}
          </h1>

          <div
            className="my-12 markdown max-w-xl text-white mx-auto w-full text-center lg:my-8"
            dangerouslySetInnerHTML={{
              __html: marked(intro.text)
            }}
          />

          <Link
            to={intro.link.href}
            className="btn-ghost light mt-6"
          >
            {intro.link.text}
          </Link>
        </div>
      </section>

      <section
        id="selskap"
        className="box-section"
      >
        <div className="box-container">
          <Img
            className="box-img"
            src={company.img.src}
            alt={company.img.alt}
          />
        </div>

        <div className="box-container">
          <div className="box-text">
            <h2 className="title">
              {company.title}
            </h2>

            <hr className="sep" />

            <div
              className="markdown"
              dangerouslySetInnerHTML={{
                __html: marked(company.text)
              }}
            />
          </div>
        </div>
      </section>

      <section
        id="partnerskap"
        className="box-section"
      >
        <div className="box-container">
          <div className="box-text">
            <h2 className="title">
              {partnership.title}
            </h2>

            <hr className="sep" />

            <div
              className="markdown"
              dangerouslySetInnerHTML={{
                __html: marked(partnership.text)
              }}
            />

            <Link
              to="contact"
              className="btn-ghost mt-12"
            >
              Ta kontakt
            </Link>
          </div>
        </div>

        <div className="box-container">
          <Img
            className="box-img"
            src={partnership.img.src}
            alt={partnership.img.alt}
          />
        </div>
      </section>

      <section
        id="strategi"
        className="box-section"
      >
        <div className="box-container">
          <Img
            className="box-img"
            src={strategy.img.src}
            alt={strategy.img.alt}
          />
        </div>

        <div className="box-container">
          <div className="box-text">
            <h2 className="title">
              {strategy.title}
            </h2>

            <hr className="sep" />

            <div
              className="markdown"
              dangerouslySetInnerHTML={{
                __html: marked(strategy.text)
              }}
            />
          </div>
        </div>
      </section>
    </main>
  )
}

interface UtviklingPageProps {
  data: {
    markdownRemark: {
      frontmatter: UtviklingPageTemplateProps & {
        meta: MetaProps
      }
    }
  }
}

const UtviklingPage: React.FC<UtviklingPageProps> = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <>
      <Meta {...frontmatter.meta} />

      <PageHeader />

      <UtviklingPageTemplate
        intro={frontmatter.intro}
        company={frontmatter.company}
        partnership={frontmatter.partnership}
        strategy={frontmatter.strategy}
      />
    </>
  )
}

export default UtviklingPage

export const UtviklingPageQuery = graphql`
  query UtviklingPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {

        meta {
          title
          description
          css
          js
        }

        intro {
          img {
            src
            alt
          }
          title
          text
          link {
            href
            text
          }
        }

        company {
          title
          text
          img {
            src
            alt
          }
        }

        partnership {
          title
          text
          img {
            src
            alt
          }
        }

        strategy {
          title
          text
          img {
            src
            alt
          }
        }

      }
    }
  }
`
